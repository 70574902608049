import React from 'react';
import { useFormContext } from "react-hook-form";
import { removeImageById } from '../../../../utils/helpers';
import { API_BASE_URL } from '../../../../utils/constants';

const CustomImagesDisplayField = props => {
  const { source, label, formData } = props;
  
  const formContext = useFormContext();
  const record = formData || formContext.getValues();
  const { setValue } = formContext;

  const images = record[source];

  const removeImage = (image) => {
    const remainingImages = JSON.parse(JSON.stringify(images));
    removeImageById(remainingImages, image.id);
    setValue(source, remainingImages, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <div className="custom-images-display-field">
      <h3 className="custom-images-display-field__label">{label || 'Current Images'}</h3>
      <div className="custom-images-display-field__content">
        {images?.filter(image => image.id).map((image, i) => (
          <div className="custom-images-display-field__image-container" key={i}>
            <img
              className="custom-images-display-field__image"
              src={`${API_BASE_URL}${image.url}`}
              alt={`${label} item` || 'current-image'}
            />
            <div
              className="custom-images-display-field__button-delete"
              onClick={() => removeImage(image)}
            >
              <div className="custom-images-display-field__icon-circle">
                <div className="custom-images-display-field__icon-dash" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomImagesDisplayField;
