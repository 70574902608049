import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

const TranslationsEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm>
      <TextInput source="translate_key" validate={required()} fullWidth />
      <TextInput source="translate_dk" label="Danish value" validate={required()} fullWidth />
      <TextInput source="translate_en" label="English value" validate={required()} fullWidth />
    </SimpleForm>
  </Edit>
);

export default TranslationsEdit;
