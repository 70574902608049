import React from 'react';
import './index.scss';

const Button = props => {
  const {
    className,
    label,
    onClick=()=>{},
    disabled,
  } = props;

  return (
    <div
      className={`button${className ? ` ${className}` : ''}${disabled ? ` -disabled` : ''}`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default Button;
