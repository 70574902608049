import React from 'react';
import { useRecordContext } from 'react-admin';

const AlteredTextField = props => {
  const {
    source,
    alterMethod=value=>value,
  } = props;

  const record = useRecordContext();

  return (
    <span>
      {record && alterMethod(record[source])}
    </span>
  );
};

export default AlteredTextField;
