import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import faqValidation from '../validation';

const FaqEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm  validate={faqValidation}>
      <TextInput source="question_dk" label="Danish question" fullWidth />
      <TextInput source="question_en" label="English question" fullWidth/>
      <TextInput source="answer_dk" label="Danish answer" multiline fullWidth />
      <TextInput source="answer_en" label="English answer" multiline fullWidth/>
    </SimpleForm>
  </Edit >
);

export default FaqEdit;
