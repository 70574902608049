const casesValidation = (values) => {
  const errors = {};
  const slugRegex = /^[A-Za-z0-9-_.~]*$/;

  if (!values?.name_dk) {
    errors.name_dk = 'Required';
  }
  if (!values?.name_en) {
    errors.name_en = 'Required';
  }
  if (!values?.number) {
    errors.number = 'Required';
  }
  if (!values?.case_header_dk) {
    errors.case_header_dk = 'Required';
  }
  if (!values?.case_header_en) {
    errors.case_header_en = 'Required';
  }
  if (!values?.case_description_dk) {
    errors.case_description_dk = 'Required';
  }
  if (!values?.case_description_en) {
    errors.case_description_en = 'Required';
  }
  if (!values?.challenge_header_dk) {
    errors.challenge_header_dk = 'Required';
  }
  if (!values?.challenge_header_en) {
    errors.challenge_header_en = 'Required';
  }
  if (!values?.challenge_description_dk) {
    errors.challenge_description_dk = 'Required';
  }
  if (!values?.challenge_description_en) {
    errors.challenge_description_en = 'Required';
  }
  if (!values?.solution_header_dk) {
    errors.solution_header_dk = 'Required';
  }
  if (!values?.solution_header_en) {
    errors.solution_header_en = 'Required';
  }
  if (!values?.solution_description_dk) {
    errors.solution_description_dk = 'Required';
  }
  if (!values?.solution_description_en) {
    errors.solution_description_en = 'Required';
  }
  if (!values?.result_header_dk) {
    errors.result_header_dk = 'Required';
  }
  if (!values?.result_header_en) {
    errors.result_header_en = 'Required';
  }
  if (!values?.result_description_dk) {
    errors.result_description_dk = 'Required';
  }
  if (!values?.result_description_en) {
    errors.result_description_en = 'Required';
  }
  if (!values?.slug) {
    errors.slug = 'Required';
  }
  if (!values.slug?.match(slugRegex)) {
    errors.slug = 'Slug field can only contain url-friendly characters';
  }
  if (!values?.order) {
    errors.order = 'Required';
  }
  if (values?.order % 1 !== 0) {
    errors.order = 'Order must be a whole number';
  }
  return errors;
};

export default casesValidation;
