import React from 'react';
import { API_BASE_URL, API_URL } from '../../../../utils/constants';
import Button from '../../../common/ui/Button';
import FileUploadButton from '../../../common/ui/FileUploadButton';
import VideoField from '../../../common/ui/VideoField';
import './index.scss';

const IntroVideoEdit = () => {
  const [videoData, setVideoData] = React.useState();
  const [videoFileDk, setVideoFileDk] = React.useState();
  const [videoFileEn, setVideoFileEn] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const getFile = (event, languageCode) => {
    if (languageCode === 'dk') {
      setVideoFileDk(event.target.files[0]);
    }
    if (languageCode === 'en') {
      setVideoFileEn(event.target.files[0]);
    }
  };

  const getUrl = languageCode => {
    if (languageCode === 'dk') {
      if (videoFileDk) {
        return URL.createObjectURL(videoFileDk);
      } else if (videoData?.dk?.url) {
        return `${API_BASE_URL}${videoData?.dk?.url}`;
      }
    }
    if (languageCode === 'en') {
      if (videoFileEn) {
        return URL.createObjectURL(videoFileEn);
      } else if (videoData?.en?.url) {
        return `${API_BASE_URL}${videoData?.en?.url}`;
      }
    }

    return null;
  };

  const submitVideos = async () => {
    const formData = new FormData();
    formData.append('data', JSON.stringify({}));
    if (!videoFileDk && !videoFileEn) {
      return;
    }
    if (videoFileDk) {
      formData.append('files.video_dk', videoFileDk);
    }
    if (videoFileEn) {
      formData.append('files.video_en', videoFileEn);
    }

    const token = JSON.parse(localStorage.getItem('token'))?.jwt || '';

    const url = `${API_URL}/intro-video`;
      
    const options = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    try {
      setIsLoading(true);
      await fetch(url, options).then(res => res.json());
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    const fetchVideos = async () => {
      const token = JSON.parse(localStorage.getItem('token'))?.jwt || '';

      const url = `${API_URL}/intro-video`;
        
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setIsLoading(true);
        const response = await fetch(url, options).then(res => res.json());

        if (response) {
          setVideoData(response);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="intro-video-edit-form">
      <h3 className="intro-video-edit-form__title">Danish Intro Video</h3>
      <div className="intro-video-edit-form__video-container">
        <VideoField
          className="intro-video-edit-form__video-field"
          url={getUrl('dk')}
        />
      </div>
      <FileUploadButton
        className="intro-video-edit-form__upload-button"
        label="Upload new mp4 video"
        accept={'video/mp4'}
        onChange={(e) => getFile(e, 'dk')}
      />
      <div className="intro-video-edit-form__horizontal-line" />
      <h3 className="intro-video-edit-form__title">English Intro Video</h3>
      <div className="intro-video-edit-form__video-container">
        <VideoField
          className="intro-video-edit-form__video-field"
          url={getUrl('en')}
        />
      </div>
      <FileUploadButton
        className="intro-video-edit-form__upload-button"
        label="Upload new mp4 video"
        accept={'video/mp4'}
        onChange={(e) => getFile(e, 'en')}
      />
      <div className={`'intro-video-edit-form__loading-layer' ${isLoading ? '-active' : ''}`}/>
      <div className="intro-video-edit-form__horizontal-line" />
      <div className="intro-video-edit-form__button-container">
        <Button
          className="intro-video-edit-form__submit-button -full-blue"
          label="Save"
          onClick={submitVideos}
          disabled={!videoFileDk && !videoFileEn}
        />
        <Button
          className="intro-video-edit-form__cancel-button"
          label="Cancel"
          onClick={reloadPage}
          disabled={!videoFileDk && !videoFileEn}
        />
      </div>
    </div>
  );
};

export default IntroVideoEdit;
