import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

const ToolsCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Name" validate={required()} fullWidth />
    </SimpleForm>
  </Create >
);

export default ToolsCreate;
