import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

const TranslationsCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="translate_key" validate={required()} fullWidth />
      <TextInput source="translate_dk" label="Danish value" validate={required()} fullWidth />
      <TextInput source="translate_en" label="English value" validate={required()} fullWidth />
    </SimpleForm>
  </Create >
);

export default TranslationsCreate;
