import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { PostFilter } from '../../../common/Filters';

const TranslationsList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="translate_key" label="Translation key" fullWidth />
      <TextField source="translate_dk" label="Danish value" fullWidth />
      <TextField source="translate_en" label="English value" fullWidth />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default TranslationsList;
