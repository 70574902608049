import React from 'react';
import './index.scss';

const FileUploadButton = props => {
  const {
    className,
    label,
    onChange=()=>{},
    disabled,
    accept,
  } = props;

  const inputRef = React.useRef();

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        accept={accept || '*'}
        onChange={(e) => onChange(e)}
        hidden
      />
      <div
        className={`file-upload-button${className ? ` ${className}` : ''}${disabled ? ` -disabled` : ''}`}
        onClick={handleClick}
      >
        {label}
      </div>
    </>
  );
};

export default FileUploadButton;
