import React from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  ImageField,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  ImageInput,
  NumberInput,
} from 'react-admin';
import CustomImagesDisplayField from '../../../common/Fields/CustomImagesDisplayField';
import casesValidation from '../validation';

const CasesCreate = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm validate={casesValidation}>
      <TextInput source="name_dk" label="Danish name" fullWidth />
      <TextInput source="name_en" label="English name" fullWidth />
      <TextInput source="number" fullWidth />
      <TextInput source="slug" fullWidth />
      <NumberInput source="order" />

      <ImageInput source="case_images" label="Case images" accept="image/*" multiple placeholder={<p>Drop your images here</p>}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <FormDataConsumer>
        {({ formData }) => <CustomImagesDisplayField source="case_images" label="Case Images" formData={formData} />}
      </FormDataConsumer>

      <TextInput source="case_header_dk" label="Danish case header" fullWidth />
      <TextInput source="case_header_en" label="English case header" fullWidth />
      <TextInput source="case_description_dk" label="Danish case description" multiline fullWidth />
      <TextInput source="case_description_en" label="English case description" multiline fullWidth />

      <ImageInput source="challenge_images" label="Challenge images" accept="image/*" multiple placeholder={<p>Drop your images here</p>}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <FormDataConsumer>
        {({ formData }) => <CustomImagesDisplayField source="challenge_images" label="Challenge Images" formData={formData} />}
      </FormDataConsumer>

      <TextInput source="challenge_header_dk" label="Danish challenge header" fullWidth />
      <TextInput source="challenge_header_en" label="English challenge header" fullWidth />
      <TextInput source="challenge_description_dk" label="Danish challenge description" multiline fullWidth />
      <TextInput source="challenge_description_en" label="English challenge description" multiline fullWidth />

      <ImageInput source="solution_images" label="Solution images" accept="image/*" multiple placeholder={<p>Drop your images here</p>}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <FormDataConsumer>
        {({ formData }) => <CustomImagesDisplayField source="solution_images" label="Solution Images" formData={formData} />}
      </FormDataConsumer>

      <TextInput source="solution_header_dk" label="Danish solution header" fullWidth />
      <TextInput source="solution_header_en" label="English solution header" fullWidth />
      <TextInput source="solution_description_dk" label="Danish solution description" multiline fullWidth />
      <TextInput source="solution_description_en" label="English solution description" multiline fullWidth />

      <ImageInput source="result_images" label="Result images" accept="image/*" multiple placeholder={<p>Drop your images here</p>}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <FormDataConsumer>
        {({ formData }) => <CustomImagesDisplayField source="result_images" label="Result Images" formData={formData} />}
      </FormDataConsumer>

      <TextInput source="result_header_dk" label="Danish result header" fullWidth />
      <TextInput source="result_header_en" label="English result header" fullWidth />
      <TextInput source="result_description_dk" label="Danish result description" multiline fullWidth />
      <TextInput source="result_description_en" label="English result description" multiline fullWidth />

      <ReferenceArrayInput reference="candidates" source="candidates"  perPage={10000} >
        <AutocompleteArrayInput
          optionText="profile_name"
          filterToQuery={searchText => ({
              searchReference: searchText,
            })
          }
        />
      </ReferenceArrayInput>
      <TextInput source="createdAt" label="Created At" disabled fullWidth />
      <TextInput source="updatedAt" label="Updated At" disabled fullWidth />
    </SimpleForm>
  </Edit >
);

export default CasesCreate;
