import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { PostFilter } from '../../../common/Filters';

const SubcategoriesList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="title_dk" label="Danish title" fullWidth />
      <TextField source="title_en" label="English title" fullWidth />
      <TextField source="slug" label="Slug" fullWidth />
      <TextField source="order" fullWidth />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default SubcategoriesList;
