import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';
import subcategoriesValidation from '../validation';

const SubcategoriesEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm validate={subcategoriesValidation}>
      <TextInput source="title_dk" label="Danish title" fullWidth />
      <TextInput source="title_en" label="English title" fullWidth />
      <TextInput source="slug" label="Slug" fullWidth />
      <NumberInput source="order" />
    </SimpleForm>
  </Edit>
);

export default SubcategoriesEdit;
