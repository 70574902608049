import React from 'react';
import {
  AutocompleteArrayInput,
  Create,
  FileInput,
  ImageField,
  NumberInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import caseCategoriesValidation from '../validation';

const CaseCategoriesCreate = props => (
  <Create {...props}>
    <SimpleForm validate={caseCategoriesValidation}>
      <FileInput source="image" label="Background" accept="image/*" placeholder={<p>Drop your image here</p>}>
        <ImageField source="src" title="title" />
      </FileInput>
      <TextInput source="name_dk" label="Danish name" fullWidth />
      <TextInput source="header_dk" label="Danish header" fullWidth />
      <TextInput source="description_dk" label="Danish description" fullWidth />
      <TextInput source="name_en" label="English name" fullWidth />
      <TextInput source="header_en" label="English header" fullWidth />
      <TextInput source="description_en" label="English description" fullWidth />
      <TextInput source="slug" label="Slug" fullWidth />
      <NumberInput source="order" />
      <ReferenceArrayInput reference="case-list" source="cases" perPage={10000} >
        <AutocompleteArrayInput
          optionText="name_en"
          filterToQuery={searchText => ({
              searchReference: searchText,
            })
          }
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create >
);

export default CaseCategoriesCreate;
