import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';
import { usersValidationEdit } from '../validation';

const UsersEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm validate={usersValidationEdit}>
      <TextInput source="name" label="Name" fullWidth />
      <TextInput source="email" label="Email" fullWidth disabled/>
      <TextInput source="password" label="Password" fullWidth />
      <TextInput source="phone" label="Phone" fullWidth />
      <BooleanInput source="confirmed" label="Is Confirmed" fullWidth />
      <ReferenceInput source="role" reference="role-list" label="Role" >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default UsersEdit;
