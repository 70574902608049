import React from 'react';
import { Edit, SimpleForm, FormDataConsumer, useStore, Datagrid, TextField, ArrayField } from 'react-admin';
import { API_URL, DATAFILES, TRACKING_EVENT_TYPES } from '../../../../utils/constants';
import { createISOString, trimISODate } from '../../../../utils/helpers';
import AlteredTextField from '../../../common/Fields/AlteredTextField';
import Button from '../../../common/ui/Button';
import './index.scss';

const TrackingUsersEdit = props => {
  const dataFilterDates = useStore('dataFilterDates', null)[0];
  const [fromDateTimestamp, setFromDateTimestamp] = React.useState();
  const [toDateTimestamp, setToDateTimestamp] = React.useState();

  const downloadDatafile = async (datafileMeta, userId, userEmail) => {
    try {
      const token = JSON.parse(localStorage.getItem('token'))?.jwt || '';

      const {
        fromDay,
        fromMonth,
        fromYear,
        toDay,
        toMonth,
        toYear,
      } = dataFilterDates;

      const fromDateDisplayed = `${fromDay}-${fromMonth}-${fromYear}`;
      const toDateDisplayed = `${toDay}-${toMonth}-${toYear}`;
  
      const url = `${API_URL}${datafileMeta.GET_URL_SUFFIX_USERS(userId)}?from=${fromDateTimestamp}&to=${toDateTimestamp}`;
        
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await fetch(url, options).then(res => res.json());
      const csvData = response;
      csvData.unshift(['sep=,']);
  
      const csvContent = csvData?.map(rowArray => rowArray.join(",")).join("\n");
  
      var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
      const link = document.createElement("a");

      if (link.download !== undefined) {
        const downloadUrl = URL.createObjectURL(blob);
        link.setAttribute("href", downloadUrl);
        link.setAttribute("download", `${datafileMeta.NAME}_${userEmail}_from-${fromDateDisplayed}_to-${toDateDisplayed}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('This browser does not support HTML5 download attribute');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const isTypeBrowse = type => {
    return type === TRACKING_EVENT_TYPES.BROWSE ? 'X' : '';
  };

  const isTypeSearch = type => {
    return type === TRACKING_EVENT_TYPES.SEARCH ? 'X' : '';
  };

  React.useEffect(() => {
    if (dataFilterDates) {
      const {
        fromDay,
        fromMonth,
        fromYear,
        toDay,
        toMonth,
        toYear,
      } = dataFilterDates;

      const fromDateISO = createISOString(fromYear, fromMonth, fromDay);
      const toDateISO = createISOString(toYear, toMonth, toDay, true);
      setFromDateTimestamp(new Date(fromDateISO).getTime());
      setToDateTimestamp(new Date(toDateISO).getTime());
    }
  }, [dataFilterDates]);

  return (
    <div className="tracking-users-edit">
      {fromDateTimestamp && toDateTimestamp && (
        <Edit
          {...props}
          mutationMode="pessimistic"
          queryOptions={{ meta: {
            from: fromDateTimestamp,
            to: toDateTimestamp,
          }}}
        >
          <SimpleForm toolbar={null}>
            <FormDataConsumer>
              {({ formData }) => {
                const {
                  fromDay,
                  fromMonth,
                  fromYear,
                  toDay,
                  toMonth,
                  toYear,
                } = dataFilterDates;

                return (
                  <>
                    <h2 className="tracking-users-edit__title">
                      {formData.email}
                    </h2>
                    <div className="tracking-users-edit__summary">
                      <h3 className="tracking-users-edit__data-line">
                        TIME FRAME
                        <span className="tracking-users-edit__data">
                          {`${fromDay}.${fromMonth}.${fromYear} - ${toDay}.${toMonth}.${toYear}`}
                        </span>
                      </h3>
                      <h3 className="tracking-users-edit__data-line">
                        SAVED CANDIDATES
                        <span className="tracking-users-edit__data">
                          {formData.savedCandidates}
                        </span>
                      </h3>
                      <h3 className="tracking-users-edit__data-line">
                        TIME SPENT
                        <span className="tracking-users-edit__data">
                          {`${formData.timeSpent} min`}
                        </span>
                      </h3>
                    </div>

                    {formData.candidatesLookedAt?.length ? (
                      <div className="tracking-users-edit__array-field-container">
                        <ArrayField source="candidatesLookedAt">
                          <Datagrid>
                            <TextField source="name" label="Candidates Looked At" />
                            <AlteredTextField source="createdAt" alterMethod={trimISODate} label="Date Time" />
                          </Datagrid>
                        </ArrayField>
                      </div>
                    ) : (
                      <h4 className="tracking-users-edit__message">Candidates Looked At - No Data</h4>
                    )}
                    <div className="tracking-users-edit__button-container">
                      <Button
                        className="tracking-users-edit__button-download -full-blue"
                        label="Download"
                        onClick={() => downloadDatafile(DATAFILES.CANDIDATES_LOOKED_AT, formData.id, formData.email)}
                        disabled={!formData.candidatesLookedAt?.length}
                      />
                    </div>

                    {formData.candidatesSaved?.length ? (
                      <div className="tracking-users-edit__array-field-container">
                        <ArrayField source="candidatesSaved">
                          <Datagrid
                            data={formData.candidatesSaved}
                          >
                            <TextField source="name" label="Candidates Saved" />
                            <AlteredTextField source="createdAt" alterMethod={trimISODate} label="Date Time" />
                          </Datagrid>
                        </ArrayField>
                      </div>
                    ) : (
                      <h4 className="tracking-users-edit__message">Candidates Saved - No Data</h4>
                    )}
                    <div className="tracking-users-edit__button-container">
                      <Button
                        className="tracking-users-edit__button-download -full-blue"
                        label="Download"
                        onClick={() => downloadDatafile(DATAFILES.CANDIDATES_SAVED, formData.id, formData.email)}
                        disabled={!formData.candidatesSaved?.length}
                      />
                    </div>

                    {formData.tools?.length ? (
                      <div className="tracking-users-edit__array-field-container">
                        <ArrayField source="tools">
                          <Datagrid>
                            <TextField source="name" label="Selected Tools" />
                            <AlteredTextField source="createdAt" alterMethod={trimISODate} label="Date Time" />
                          </Datagrid>
                        </ArrayField>
                      </div>  
                    ) : (
                      <h4 className="tracking-users-edit__message">Tools - No Data</h4>
                    )}
                    <div className="tracking-users-edit__button-container">
                      <Button
                        className="tracking-users-edit__button-download -full-blue"
                        label="Download"
                        onClick={() => downloadDatafile(DATAFILES.SELECTED_TOOLS, formData.id, formData.email)}
                        disabled={!formData.tools?.length}
                      />
                    </div>

                    {formData.skills?.length ? (
                      <div className="tracking-users-edit__array-field-container">
                        <ArrayField source="skills">
                          <Datagrid>
                            <TextField source="name" label="Selected Skills" />
                            <AlteredTextField source="createdAt" alterMethod={trimISODate} label="Date Time" />
                          </Datagrid>
                        </ArrayField>
                      </div>
                    ) : (
                      <h4 className="tracking-users-edit__message">Skills - No Data</h4>
                    )}
                    <div className="tracking-users-edit__button-container">
                      <Button
                        className="tracking-users-edit__button-download -full-blue"
                        label="Download"
                        onClick={() => downloadDatafile(DATAFILES.SELECTED_SKILLS, formData.id, formData.email)}
                        disabled={!formData.skills?.length}
                      />
                    </div>

                    {formData.mainCategories?.length ? (
                      <div className="tracking-users-edit__array-field-container -has-search-type">
                        <ArrayField source="mainCategories">
                          <Datagrid>
                            <TextField source="name" label="Industry" />
                            <AlteredTextField source="type" alterMethod={isTypeBrowse} label="Browse" />
                            <AlteredTextField source="type" alterMethod={isTypeSearch} label="Search" />
                            <AlteredTextField source="createdAt" alterMethod={trimISODate} label="Date Time" />
                          </Datagrid>
                        </ArrayField>
                      </div>  
                    ) : (
                      <h4 className="tracking-users-edit__message">Industry - No Data</h4>
                    )}
                    <div className="tracking-users-edit__button-container">
                      <Button
                        className="tracking-users-edit__button-download -full-blue"
                        label="Download"
                        onClick={() => downloadDatafile(DATAFILES.INDUSTRY, formData.id, formData.email)}
                        disabled={!formData.mainCategories?.length}
                      />
                    </div>
                    
                    {formData.subcategories?.length ? (
                      <div className="tracking-users-edit__array-field-container -has-search-type">
                        <ArrayField source="subcategories">
                          <Datagrid>
                            <TextField source="name" label="Specialization" />
                            <AlteredTextField source="type" alterMethod={isTypeBrowse} label="Browse" />
                            <AlteredTextField source="type" alterMethod={isTypeSearch} label="Search" />
                            <AlteredTextField source="createdAt" alterMethod={trimISODate} label="Date Time" />
                          </Datagrid>
                        </ArrayField>
                      </div>
                    ) : (
                      <h4 className="tracking-users-edit__message">Specialization - No Data</h4>
                    )}
                    <div className="tracking-users-edit__button-container">
                      <Button
                        className="tracking-users-edit__button-download -full-blue"
                        label="Download"
                        onClick={() => downloadDatafile(DATAFILES.SPECIALIZATION, formData.id, formData.email)}
                        disabled={!formData.subcategories?.length}
                      />
                    </div>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Edit>
      )}
    </div>
  );
}

export default TrackingUsersEdit;
