const caseCategoriesValidation = (values) => {
  const errors = {};
  const slugRegex = /^[A-Za-z0-9-_.~]*$/;

  if (!values?.name_dk) {
    errors.name_dk = 'Required';
  }
  if (!values?.name_en) {
    errors.name_en = 'Required';
  }
  if (!values?.header_dk) {
    errors.header_dk = 'Required';
  }
  if (!values?.header_en) {
    errors.header_en = 'Required';
  }
  if (!values?.description_dk) {
    errors.description_dk = 'Required';
  }
  if (!values?.description_en) {
    errors.description_en = 'Required';
  }
  if (!values?.slug) {
    errors.slug = 'Required';
  }
  if (!values.slug?.match(slugRegex)) {
    errors.slug = 'Slug field can only contain url-friendly characters';
  }
  if (!values?.order) {
    errors.order = 'Required';
  }
  if (values?.order % 1 !== 0) {
    errors.order = 'Order must be a whole number';
  }
  return errors;
};

export default caseCategoriesValidation;
