import React from 'react';
import { Edit, SimpleForm, BooleanInput, TextInput } from 'react-admin';

const WantedCandidatesEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm>
      <BooleanInput source="is_completed" label="Connected" fullWidth />
      <TextInput source="name" label="User Name" fullWidth disabled />
      <TextInput source="user_email" label="User Email" fullWidth disabled />
      <TextInput source="user_phone" label="User Phone" fullWidth disabled />
      <TextInput source="candidate_profile_name" label="Candidate Name" fullWidth disabled />
      <TextInput source="candidate_profile_number" label="Candidate Number" fullWidth disabled />
      <TextInput source="contact_method" fullWidth disabled />
    </SimpleForm>
  </Edit>
);

export default WantedCandidatesEdit;
