import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import faqValidation from '../validation';

const FaqCreate = props => (
  <Create {...props}>
    <SimpleForm  validate={faqValidation}>
      <TextInput source="question_dk" label="Danish question" fullWidth />
      <TextInput source="question_en" label="English question" fullWidth/>
      <TextInput source="answer_dk" label="Danish answer" multiline fullWidth />
      <TextInput source="answer_en" label="English answer" multiline fullWidth/>
    </SimpleForm>
  </Create >
);

export default FaqCreate;
