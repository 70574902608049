import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';
import subcategoriesValidation from '../validation';

const SubcategoriesCreate = props => (
  <Create {...props}>
    <SimpleForm validate={subcategoriesValidation}>
      <TextInput source="title_dk" label="Danish title" fullWidth />
      <TextInput source="title_en" label="English title" fullWidth />
      <TextInput source="slug" label="Slug" fullWidth />
      <NumberInput source="order" />
    </SimpleForm>
  </Create >
);

export default SubcategoriesCreate;
