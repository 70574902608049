import React from 'react';
import { Edit, SimpleForm, TextInput, required, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

const SkillsEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm>
      <TextInput source="name_dk" label="Danish name" validate={required()} fullWidth />
      <TextInput source="name_en" label="English name" validate={required()} fullWidth />
      <TextInput source="level" label="Level" fullWidth disabled />
      <ReferenceArrayInput reference="subcategories" source="subcategories">
        <AutocompleteArrayInput
          optionText="title_en"
          filterToQuery={searchText => ({
              searchReference: searchText,
            })
          }
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default SkillsEdit;
