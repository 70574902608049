import React from 'react';
import { Create, FileInput, ImageField, SimpleForm, TextInput, required } from 'react-admin';

const LanguagesCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <FileInput source="icon" label="Icon" accept="image/*" placeholder={<p>Drop your image here</p>}>
        <ImageField source="src" title="title" />
      </FileInput>
      <TextInput source="name_dk" label="Danish name" validate={required()} fullWidth />
      <TextInput source="name_en" label="English name" validate={required()} fullWidth />
    </SimpleForm>
  </Create >
);

export default LanguagesCreate;
