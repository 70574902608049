import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { PostFilter } from '../../../common/Filters';

const CasesList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name_dk" label="Danish name" fullWidth />
      <TextField source="name_en" label="English name" fullWidth />
      <TextField source="number" label="Number" fullWidth />
      <TextField source="order" fullWidth />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default CasesList;
