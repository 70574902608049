import React from 'react';
import { Edit, FileInput, ImageField, SimpleForm, TextInput, FormDataConsumer, required } from 'react-admin';
import { API_BASE_URL } from '../../../../utils/constants';

const LanguagesEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm>
      <FileInput source="icon" label="Icon" accept="image/*" placeholder={<p>Drop your image here</p>}>
        <ImageField source="src" title="title" />
      </FileInput>
  
      <FormDataConsumer>
        {({ formData }) => {
          if (formData?.icon?.url && (typeof formData?.icon?.url === 'string')) {
            return (
              <>
                <h3 className="original-image__label" >Original Icon</h3>
                <div className="original-image__container">
                  <img
                    className="original-image__image"
                    src={`${API_BASE_URL}${formData.icon.url}`}
                    alt="language"
                  />
                </div>
              </>
            );
          }
        }}
      </FormDataConsumer>

      <TextInput source="name_dk" label="Danish name" validate={required()} fullWidth />
      <TextInput source="name_en" label="English name" validate={required()} fullWidth />
    </SimpleForm>
  </Edit>
);

export default LanguagesEdit;
