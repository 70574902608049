import React from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  FileInput,
  ImageField,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  NumberInput,
} from 'react-admin';
import { API_BASE_URL } from '../../../../utils/constants';
import mainCategoriesValidation from '../validation';

const MainCategoriesEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm validate={mainCategoriesValidation}>
      <FileInput source="image" label="Background" accept="image/*" placeholder={<p>Drop your image here</p>}>
        <ImageField source="src" title="title" />
      </FileInput>

      <FormDataConsumer>
        {({ formData }) => {
          if (formData?.image?.url && (typeof formData?.image.url === 'string')) {
            return (
              <>
                <h3 className="original-image__label" >Original Image</h3>
                <div className="original-image__container">
                  <img
                    className="original-image__image"
                    src={`${API_BASE_URL}${formData.image.url}`}
                    alt="background"
                  />
                </div>
              </>
            );
          }
        }}
      </FormDataConsumer>

      <TextInput source="title_dk" label="Danish title" fullWidth />
      <TextInput source="description_dk" label="Danish description" fullWidth />
      <TextInput source="title_en" label="English title" fullWidth />
      <TextInput source="description_en" label="English description" fullWidth />
      <TextInput source="slug" label="Slug" fullWidth />
      <NumberInput source="order" />
      <ReferenceArrayInput reference="subcategories" source="subcategories">
        <AutocompleteArrayInput
          optionText="title_en"
          filterToQuery={searchText => ({
              searchReference: searchText,
            })
          }
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit >
);

export default MainCategoriesEdit;
