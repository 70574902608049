export const API_BASE_URL = process.env.REACT_APP_URL;
export const API_URL = `${API_BASE_URL}/api`;
export const API_AUTH_URL = `${API_URL}/auth/local`;

export const FILE_FIELD_NAMES = ['icon', 'image', 'avatar'];
export const FILE_FIELD_NAMES_MULTI = ['case_images', 'challenge_images', 'solution_images', 'result_images'];
export const STORAGE_KEY_AUTH_TOKEN = 'token';

export const DAY_IN_MILLISECS = 24 * 60 * 60 * 1000;

export const DATAFILES = {
  CANDIDATES_LOOKED_AT: {
    NAME: 'candidates_looked_at',
    GET_URL_SUFFIX_USERS: (userId) => `/tracking-users/${userId}/downloads/candidates-looked-at`,
    URL_SUFFIX_GLOBAL: '/tracking-global/1/downloads/candidates-looked-at',
  },
  CANDIDATES_SAVED: {
    NAME: 'candidates_saved',
    GET_URL_SUFFIX_USERS: (userId) => `/tracking-users/${userId}/downloads/candidates-saved`,
    URL_SUFFIX_GLOBAL: '/tracking-global/1/downloads/candidates-saved',
  },
  SELECTED_TOOLS: {
    NAME: 'selected_tools',
    GET_URL_SUFFIX_USERS: (userId) => `/tracking-users/${userId}/downloads/tools`,
    URL_SUFFIX_GLOBAL: '/tracking-global/1/downloads/tools',
  },
  SELECTED_SKILLS: {
    NAME: 'selected_skills',
    GET_URL_SUFFIX_USERS: (userId) => `/tracking-users/${userId}/downloads/skills`,
    URL_SUFFIX_GLOBAL: '/tracking-global/1/downloads/skills',
  },
  INDUSTRY: {
    NAME: 'industry',
    GET_URL_SUFFIX_USERS: (userId) => `/tracking-users/${userId}/downloads/main-categories`,
    URL_SUFFIX_GLOBAL: '/tracking-global/1/downloads/main-categories',
  },
  SPECIALIZATION: {
    NAME: 'specialization',
    GET_URL_SUFFIX_USERS: (userId) => `/tracking-users/${userId}/downloads/subcategories`,
    URL_SUFFIX_GLOBAL: '/tracking-global/1/downloads/subcategories',
  },
};

export const FIELD_NAMES = {
  EMAIL: 'email',
  NAME: 'name',
  NAME_DK: 'name_dk',
  NAME_EN: 'name_en',
  PROFILE_NAME: 'profile_name',
  PROFILE_NUMBER: 'profile_number',
  QUESTION_DK: 'question_dk',
  QUESTION_EN: 'question_en',
  TITLE_DK: 'title_dk',
  TITLE_EN: 'title_en',
  TRANSLATE_DK: 'translate_dk',
  TRANSLATE_EN: 'translate_en',
};

export const RESOURCES_FILTER_FIELDS = {
  'candidates': [
    FIELD_NAMES.PROFILE_NAME,
    FIELD_NAMES.PROFILE_NUMBER,
  ],
  'case-categories': [
    FIELD_NAMES.NAME_DK,
    FIELD_NAMES.NAME_EN,
  ],
  'case-list': [
    FIELD_NAMES.NAME_DK,
    FIELD_NAMES.NAME_EN,
  ],
  'faqs': [
    FIELD_NAMES.QUESTION_DK,
    FIELD_NAMES.QUESTION_EN,
  ],
  'languages': [
    FIELD_NAMES.NAME_DK,
    FIELD_NAMES.NAME_EN,
  ],
  'main-categories': [
    FIELD_NAMES.TITLE_DK,
    FIELD_NAMES.TITLE_EN,
  ],
  'skills': [
    FIELD_NAMES.NAME_DK,
    FIELD_NAMES.NAME_EN,
  ],
  'subcategories': [
    FIELD_NAMES.TITLE_DK,
    FIELD_NAMES.TITLE_EN,
  ],
  'tools': [
    FIELD_NAMES.NAME,
  ],
  'translations': [
    FIELD_NAMES.TRANSLATE_DK,
    FIELD_NAMES.TRANSLATE_EN,
  ],
  'user-list': [
    FIELD_NAMES.EMAIL,
    FIELD_NAMES.NAME,
  ],
};

export const RESOURCES_RELATION_FILTER_FIELDS = {
  'candidates': [
    FIELD_NAMES.PROFILE_NAME,
  ],
  'case-categories': [
    FIELD_NAMES.NAME_EN,
  ],
  'case-list': [
    FIELD_NAMES.NAME_EN,
  ],
  'faqs': [
    FIELD_NAMES.QUESTION_EN,
  ],
  'languages': [
    FIELD_NAMES.NAME_EN,
  ],
  'main-categories': [
    FIELD_NAMES.TITLE_EN,
  ],
  'skills': [
    FIELD_NAMES.NAME_EN,
  ],
  'subcategories': [
    FIELD_NAMES.TITLE_EN,
  ],
  'tools': [
    FIELD_NAMES.NAME,
  ],
  'translations': [
    FIELD_NAMES.TRANSLATE_EN,
  ],
  'user-list': [
    FIELD_NAMES.NAME,
  ],
};

export const TRACKING_EVENT_TYPES = {
  BROWSE: 'browse',
  SEARCH: 'search',
};
