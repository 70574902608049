import React from 'react';
import {
  Datagrid,
  DateField,
  FormDataConsumer,
  List,
  NumberInput,
  ResourceContextProvider,
  SimpleForm,
  TextField,
  useStore
} from 'react-admin';
import { DAY_IN_MILLISECS } from '../../../../utils/constants';
import { createISOString } from '../../../../utils/helpers';
import AlteredTextField from '../../../common/Fields/AlteredTextField';
import CombinedTextField from '../../../common/Fields/CombinedTextField';
import Button from '../../../common/ui/Button';
import './index.scss';

const TrackingList = props => {
  const [dataFilterDates, setDataFilterDates] = useStore('dataFilterDates', null);
  const [dateError, setDateError] = React.useState('');
  const [fromDateTimestamp, setFromDateTimestamp] = React.useState();
  const [toDateTimestamp, setToDateTimestamp] = React.useState();

  const thisYear = new Date().getFullYear();
  const defaultValues = {
    fromDay: 1,
    fromMonth: 1,
    fromYear: thisYear,
    toDay: 1,
    toMonth: 1,
    toYear: thisYear,
  };

  const fetchList = async (formData) => {
    const {
      fromDay,
      fromMonth,
      fromYear,
      toDay,
      toMonth,
      toYear,
    } = formData;
    
    const fromDateISO = createISOString(fromYear, fromMonth, fromDay);
    const toDateISO = createISOString(toYear, toMonth, toDay, true);
    let fromDateTimestamp;
    let toDateTimestamp;

    try {
      fromDateTimestamp = new Date(fromDateISO).getTime();
      if (!fromDateTimestamp) {
        throw new Error('Failed to create timestamp. "fromDateISO" is not a valid date');
      }
    } catch (err) {
      console.error(err);
      setDateError('"From date" is not valid');
      return;
    }

    try {
      toDateTimestamp = new Date(toDateISO).getTime();
      if (!toDateTimestamp) {
        throw new Error('Failed to create timestamp. "fromDateISO" is not a valid date');
      }
    } catch (err) {
      console.error(err);
      setDateError('"To date" is not valid');
      return;
    }

    if (fromDateTimestamp > toDateTimestamp) {
      setDateError('"From date" must not be later than "To date"');
      return;
    }

    setFromDateTimestamp(fromDateTimestamp);
    setToDateTimestamp(toDateTimestamp);
    setDateError('');
    setDataFilterDates(formData);
  };

  const calculateUserNewValue = createdAt => {
    const nowTimestamp = new Date().getTime();
    try {
      const createdAtTimestamp = new Date(createdAt).getTime();
      if (!createdAtTimestamp) {
        throw new Error('Failed to create timestamp. "createdAt" is not a valid date');
      }
      if (nowTimestamp - createdAtTimestamp < 7 * DAY_IN_MILLISECS) {
        return 'NEW';
      } else {
        return '';
      }
    } catch (err) {
      console.error(err);
      return '';
    }
  };

  return (
    <div className="tracking-list-form">
      <div className="tracking-list-form__date-form">
        <SimpleForm defaultValues={ dataFilterDates || defaultValues}>
          <div className="tracking-list-form__date-container">
            <h3 className="tracking-list-form__date-text">DATA FROM</h3>
            <NumberInput source="fromDay" min="1" max="31" step="1" label="Day" />
            <NumberInput source="fromMonth" min="1" max="12" step="1" label="Month" />
            <NumberInput source="fromYear" min="2022" max={thisYear} step="1" label="Year" />
            <h3 className="tracking-list-form__date-text">TO</h3>
            <NumberInput source="toDay" min="1" max="31" step="1" label="Day" />
            <NumberInput source="toMonth" min="1" max="12" step="1" label="Month" />
            <NumberInput source="toYear" min="2022" max={thisYear} step="1" label="Year" />
          </div>
          <p className="tracking-list-form__date-error">
            {dateError}
          </p>
          <FormDataConsumer>
            {({ formData }) => (
              <Button
                className="tracking-list-form__button"
                label="REVEAL"
                onClick={() => fetchList(formData)}
              />
            )}
          </FormDataConsumer>
          <div className="tracking-list-form__horizontal-line" />
        </SimpleForm>
      </div>

      <div className="tracking-list-form__user-list">
        {!dateError && fromDateTimestamp && toDateTimestamp && (
          <ResourceContextProvider value="tracking-users">
            <h3 className="tracking-list-form__title">USER DATA</h3>
            <List
              {...props}
              disableSyncWithLocation
              queryOptions={{
                meta: {
                  from: fromDateTimestamp,
                  to: toDateTimestamp,
                },
              }}
              actions={<></>}
            >
              <Datagrid rowClick="edit">
                <TextField source="email" label="User" sortable={false} />
                <TextField source="timeSpent" label="Time Spent" sortable={false} />
                <CombinedTextField
                  source="candidatesLookedAt"
                  source2="candidatesSaved"
                  separator="/"
                  suffix=" (looked at / saved)"
                  sortable={false}
                />
                <TextField source="tools" sortable={false} />
                <TextField source="skills" sortable={false} />
                <TextField source="mainCategories" label="Industry" sortable={false} />
                <TextField source="subcategories" label="Specialization" sortable={false} />
                <DateField source="lastActive" sortable={false} />
                <AlteredTextField source="createdAt" alterMethod={calculateUserNewValue} label="" sortable={false} />
              </Datagrid>
            </List>
          </ResourceContextProvider>
        )}
      </div>

      <div className="tracking-list-form__global-list">
        {!dateError && fromDateTimestamp && toDateTimestamp && (
          <ResourceContextProvider value="tracking-global">
            <h3 className="tracking-list-form__title">GLOBAL DATA</h3>
            <List
              {...props}
              disableSyncWithLocation
              queryOptions={{
                meta: {
                  from: fromDateTimestamp,
                  to: toDateTimestamp,
                },
              }}
              actions={<></>}
              pagination={<></>}
            >
              <Datagrid rowClick="edit">
                <span>All users</span>
                <TextField source="timeSpent" label="Time Spent" sortable={false} />
                <CombinedTextField
                  source="candidatesLookedAt"
                  source2="candidatesSaved"
                  separator="/"
                  suffix=" (looked at / saved)"
                  sortable={false}
                />
                <TextField source="tools" sortable={false} />
                <TextField source="skills" sortable={false} />
                <TextField source="mainCategories" label="Industry" sortable={false} />
                <TextField source="subcategories" label="Specialization" sortable={false} />
                <DateField source="lastActive" sortable={false} />
                <span>&nbsp;&nbsp;&nbsp;</span>
              </Datagrid>
            </List>
          </ResourceContextProvider>
        )}
      </div>
    </div>
  );
};

export default TrackingList;
