import React from 'react';
import { List, Datagrid, BooleanField, TextField, DateField } from 'react-admin';

const DreamCandidatesList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="creator_email" label="Creator Email" fullWidth />
      <BooleanField source="is_found" />
      <TextField source="email" label="User Email" fullWidth />
      <TextField source="name" label="Name" fullWidth />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default DreamCandidatesList;
