import qs from 'qs';
import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
import { FILE_FIELD_NAMES, FILE_FIELD_NAMES_MULTI, RESOURCES_FILTER_FIELDS, RESOURCES_RELATION_FILTER_FIELDS } from '../utils/constants';
import { getToken } from '../utils/storageutils';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const jwt = getToken();

  options.headers.set('Authorization', `Bearer ${jwt}`);
  return fetchUtils.fetchJson(url, options);
};

const handleFileUpload = (params) => {
  const paramKeys = Object.keys(params);
  const formData = new FormData();
  const data = {};

  paramKeys.forEach(key => {
    if (!FILE_FIELD_NAMES.includes(key) && !FILE_FIELD_NAMES_MULTI.includes(key)) {
      data[key] = params[key];
    } else {
      if (FILE_FIELD_NAMES.includes(key) && typeof params[key] !== 'string') {
        formData.append(`files.${key}`, params[key]?.rawFile);
      }
      if (FILE_FIELD_NAMES_MULTI.includes(key)) {
        if (params[key] instanceof Array) {
          const oldImages = [];
          for (const file of params[key]) {

            if (file.rawFile) {
              formData.append(`files.${key}`, file.rawFile);
            } else if (file.id) {
              oldImages.push(file);
            }
          }

          data[key] = oldImages;
        } else {
          // Only 1 file uploaded to multiselect input field
          if (typeof params[key] !== 'string') {
            formData.append(`files.${key}`, params[key]?.rawFile);
            data[key] = [];
          }
        }
      }
    }
  });
  formData.append('data', JSON.stringify(data));

  return formData;
};

const jsonServerProvider = (apiUrl) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const filter = params.filter;
    const meta = params.meta;

    if (resource === 'wanted-candidate-list' && field === 'id') {
      field = 'is_completed';
    }

    const rawQuery = {
      pagination: {
        start: (page - 1) * perPage,
        limit: perPage,
      },
      sort: [`${field}:${order}`],
    };

    if (meta) {
      Object.keys(meta).forEach(key => {
        rawQuery[key] = meta[key];
      });
    }

    if (filter?.search && RESOURCES_FILTER_FIELDS[resource]) {
      const filterObject = {
        $or: RESOURCES_FILTER_FIELDS[resource].map(fieldName => {
          const filterPart = {};
          filterPart[fieldName] = { $containsi: filter.search };
          return filterPart;
        })
      };

      rawQuery.filters = filterObject;
    }

    if (filter?.searchReference && RESOURCES_RELATION_FILTER_FIELDS[resource]) {
      const filterObject = {
        $or: RESOURCES_RELATION_FILTER_FIELDS[resource].map(fieldName => {
          const filterPart = {};
          filterPart[fieldName] = { $containsi: filter.searchReference };
          return filterPart;
        })
      };

      rawQuery.filters = filterObject;
    }

    const query = qs.stringify(rawQuery);

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }

      const data = json?.data?.map(entity => {
        const flatEntity = entity.attributes;
        flatEntity.id = entity.id;
        return flatEntity;
      })

      return {
        data: data,
        total: parseInt(
          headers.get('x-total-count').split('/').pop(),
          10
        ),
      };
    });
  },

  getOne: (resource, params) => {
    const { meta } = params;

    const rawQuery = {};

    if (meta) {
      Object.keys(meta).forEach(key => {
        rawQuery[key] = meta[key];
      });
    }

    const query = qs.stringify(rawQuery);

    return httpClient(`${apiUrl}/${resource}/${params.id}?${query}`).then(({ json }) => {
      const data = json?.data?.attributes || {};
      data.id = json?.data?.id;

      return ({
        data: data,
      });
    });
  },

  getMany: (resource, params) => {
    const rawQuery = {
      pagination: {
        start: 0,
        limit: 100,
      },
    };

    if (params?.ids) {
      const filterObject = {
        $or: params.ids.map(idOrObject => {
          // ReactAdmin passes params.ids sometimes as an array of objects with ids
          // other times as an array of ids 
          if (idOrObject.id) {
            const id = idOrObject.id;
            return { id: { $eq: id} };
          } else {
            const id = idOrObject;
            return { id: { $eq: id } };
          }
        })
      };

      rawQuery.filters = filterObject;
    }

    const query = qs.stringify(rawQuery);

    const url = `${apiUrl}/${resource}?${query}`;
    return httpClient(url).then(({ json }) => {
      const data = json?.data?.map(entity => {
        const flatEntity = entity.attributes;
        flatEntity.id = entity.id;
        return flatEntity;
      })

      return { data: data };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = qs.stringify({
      pagination: {
        start: (page - 1) * perPage,
        limit: perPage,
      },
      sort: [`${field}:${order}`],
    });

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }

      const data = json?.data?.map(entity => {
        const flatEntity = entity.attributes;
        flatEntity.id = entity.id;
        return flatEntity;
      })

      return {
        data: data,
        total: parseInt(
          headers.get('x-total-count').split('/').pop(),
          10
        ),
      };
    });
  },

  update: (resource, params) => {
    const fieldNames = Object.keys(params.data);

    // handle file upload
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      if (FILE_FIELD_NAMES.includes(fieldName) || (FILE_FIELD_NAMES_MULTI.includes(fieldName))) {
        const formData = handleFileUpload(params.data);

        return (
          httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: formData,
          }).then(({ json }) => {
            const data = json?.data?.attributes || {};
            data.id = json?.data?.id;
      
            return ({ data: data });
          })
        );
      }
    }

    return (
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify({ data: params.data }),
      }).then(({ json }) => {
        const data = json?.data?.attributes || {};
        data.id = json?.data?.id;
  
        return ({ data: data });
      })
    );
  },
    

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify({ data: params.data }),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json?.data?.id) })),

  create: (resource, params) => {
    const fieldNames = Object.keys(params.data);

    // handle file upload
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      if (FILE_FIELD_NAMES.includes(fieldName) || (FILE_FIELD_NAMES_MULTI.includes(fieldName))) {
        const formData = handleFileUpload(params.data);

        return (
          httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: formData,
          }).then(({ json }) => {
            const data = json?.data?.attributes || {};
            data.id = json?.data?.id;
      
            return ({ data: data });
          })
        );
      }
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify({ data: params.data }),
    }).then(({ json }) => ({
      data: { ...params.data, id: json?.data?.id },
    }));
  },
    

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => {
      const data = json?.data?.attributes || {};
      data.id = json?.data?.id;

      return ({ data: data });
    }),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json?.data?.id) })),
});

export default jsonServerProvider;
