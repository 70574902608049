const faqValidation = (values) => {
  const errors = {};

  if (!values?.question_dk) {
    errors.question_dk = 'Required';
  }
  if (!values?.question_en) {
    errors.question_en = 'Required';
  }
  if (!values?.answer_dk) {
    errors.answer_dk = 'Required';
  }
  if (!values?.answer_en) {
    errors.answer_en = 'Required';
  }
  return errors;
};

export default faqValidation;
