import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { usersValidationCreate } from '../validation';

const UsersCreate = props => (
  <Create {...props}>
    <SimpleForm validate={usersValidationCreate} >
      <TextInput source="name" label="Name" fullWidth />
      <TextInput source="email" label="Email" fullWidth />
      <TextInput source="password" label="Password" fullWidth />
      <TextInput source="phone" label="Phone" fullWidth />
      <ReferenceInput source="role" reference="role-list" label="Role" >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default UsersCreate;
