import React from 'react';
import {
  AutocompleteArrayInput,
  Create,
  FileInput,
  ImageField,
  NumberInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import mainCategoriesValidation from '../validation';

const MainCategoriesCreate = props => (
  <Create {...props}>
    <SimpleForm validate={mainCategoriesValidation}>
      <FileInput source="image" label="Background" accept="image/*" placeholder={<p>Drop your image here</p>}>
        <ImageField source="src" title="title" />
      </FileInput>
      <TextInput source="title_dk" label="Danish title" fullWidth />
      <TextInput source="description_dk" label="Danish description" fullWidth />
      <TextInput source="title_en" label="English title" fullWidth />
      <TextInput source="description_en" label="English description" fullWidth />
      <TextInput source="slug" label="Slug" fullWidth />
      <NumberInput source="order" />
      <ReferenceArrayInput reference="subcategories" source="subcategories">
        <AutocompleteArrayInput
          optionText="title_en"
          filterToQuery={searchText => ({
              searchReference: searchText,
            })
          }
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create >
);

export default MainCategoriesCreate;
