import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';

const SavedCandidatesList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="User Name" fullWidth />
      <TextField source="phone" label="User Phone" fullWidth />
      <TextField source="profile_number" label="Candidate Number" fullWidth />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default SavedCandidatesList;
