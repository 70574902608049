import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { PostFilter } from '../../../common/Filters';

const UsersList = (props) => (
  <List {...props} filters={<PostFilter />} >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Name" fullWidth />
      <TextField source="email" label="Email" fullWidth />
      <TextField source="role.name" label="Role" fullWidth />
      <TextField source="confirmed" label="Is Confirmed" fullWidth />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default UsersList;
