import React from 'react';
import { Create, SimpleForm, TextInput, required, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

const SkillsCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name_dk" label="Danish name" validate={required()} fullWidth />
      <TextInput source="name_en" label="English name" validate={required()} fullWidth />
      <ReferenceArrayInput reference="subcategories" source="subcategories">
        <AutocompleteArrayInput
          optionText="title_en"
          filterToQuery={searchText => ({
              searchReference: searchText,
            })
          }
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create >
);

export default SkillsCreate;
