import React from 'react';
import './index.scss';

const VideoField = props => {
  const {
    className,
    url,
  } = props;

  const videoElement = React.useRef(null);

  React.useEffect(() => {    
    videoElement.current?.load();
  }, [url]);

  return (
    <div className={`video-field ${className ? className : ''}`}>
      {url ? (
        <video
          ref={videoElement}
          className="video-field__video"
          controlsList="nodownload"
          controls
        >
          <source src={url} type="video/mp4"></source>
        </video>
      ) : (
        <div className="video-field__placeholder">
          <p className="video-field__placeholder-text">
            No video uploaded yet
          </p>
        </div>
      )}
    </div>
  );
};

export default VideoField;
