import React from 'react';
import { Edit, SimpleForm, TextInput, TextField, required } from 'react-admin';

const ToolsEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm>
      <TextInput source="name" label="Name" validate={required()} fullWidth />
      <TextField source="level" label="Level" fullWidth />
    </SimpleForm>
  </Edit>
);

export default ToolsEdit;
