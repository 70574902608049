import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput, DateInput } from 'react-admin';

const DreamCandidatesEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm>
      <TextInput source="creator_email" fullWidth disabled />
      <TextInput source="user_id" fullWidth disabled />
      <TextInput source="user_email" fullWidth disabled />
      <TextInput source="id" fullWidth disabled />
      <TextInput source="name" fullWidth />
      <BooleanInput source="is_found" />
      <TextInput source="education_text" multiline fullWidth />
      <NumberInput source="education_importance" defaultValue="0" min="0" max="10" step={1} fullWidth />
      <TextInput source="experience_text" multiline fullWidth />
      <NumberInput source="experience_importance" defaultValue="0" min="0" max="10" step={1} fullWidth />
      <TextInput source="specials_text" multiline fullWidth />
      <NumberInput source="specials_importance" defaultValue="0" min="0" max="10" step={1} fullWidth />
      <TextInput source="certifications_text" multiline fullWidth />
      <NumberInput source="certifications_importance" defaultValue="0" min="0" max="10" step={1} fullWidth />
      <TextInput source="languages_text" multiline fullWidth />
      <NumberInput source="languages_importance" defaultValue="0" min="0" max="10" step={1} fullWidth />
      <TextInput source="project_start" fullWidth />
      <TextInput source="project_duration" fullWidth />
      <TextInput source="project_description" multiline fullWidth />
      <DateInput source="created_at" fullWidth disabled />
      <DateInput source="updated_at" fullWidth disabled />
    </SimpleForm>
  </Edit>
);

export default DreamCandidatesEdit;
