export const flattenAttributes = attributes => {
  const flattened = {};
  Object.keys(attributes).forEach(key => {
    if (attributes[key].type === 'object') {
      Object.assign(flattened, flattenAttributes(attributes[key].attributes));
    } else {
      flattened[key] = attributes[key];
    }
  });
  return flattened;
};

export const removeImageById = (array, id) => {
  const image = array.find(image => image.id === id);
  const index = array.indexOf(image);
  array.splice(index, 1);
};

export const convertIntegerTo2DigitsString = numberInput => {
  const numberNumber = Number(numberInput);
  const numberString = String(numberInput);

  if (numberNumber % 1 !== 0) {
    throw new Error('Can\'t convert to 2 digits. Number must be an integer.');
  } 

  return numberNumber < 10 ? `0${numberString}` : numberString;
};

export const createISOString = (year, month, day, isEnd) => {
  const trimmedMonth = convertIntegerTo2DigitsString(month);
  const trimmedDay = convertIntegerTo2DigitsString(day);
  const hours = isEnd ? '23' : '00';
  const minutes = isEnd ? '59' : '00';
  const seconds = isEnd ? '59' : '00';
  const milliseconds = isEnd ? '999' : '000';
  
  return `${year}-${trimmedMonth}-${trimmedDay}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const trimISODate = ISODate => {
  const date = new Date(ISODate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${day}.${month}.${year} ${hour}:${minute}`;
};
