import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { PostFilter } from '../../../common/Filters';

const CandidatesList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="profile_name" label="Name" fullWidth />
      <TextField source="profile_number" label="Item No" fullWidth />
      <TextField source="profile_title_en" label="Title" fullWidth />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default CandidatesList;
