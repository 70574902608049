export const usersValidationEdit = (values) => {
  const errors = {};
  const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

  if (!values?.name) {
    errors.name = 'Required';
  }
  if (!values?.email) {
    errors.email = 'Required';
  }
  if (!values?.email?.match(emailRegex)) {
    errors.email = 'Must be a valid email address';
  }
  if (values?.password) {
    if (values?.password?.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }
  }
  if (!values?.role) {
    errors.role = 'Required';
  }
  return errors;
};

export const usersValidationCreate = (values) => {
  const errors = {};
  const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

  if (!values?.name) {
    errors.name = 'Required';
  }
  if (!values?.email) {
    errors.email = 'Required';
  }
  if (!values?.email?.match(emailRegex)) {
    errors.email = 'Must be a valid email address';
  }
  if (!values?.password) {
    errors.password = 'Required';
  }
  if (values?.password) {
    if (values?.password?.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }
  }
  if (!values?.role) {
    errors.role = 'Required';
  }
  return errors;
};
