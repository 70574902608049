import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const SavedCandidatesEdit = props => (
  <Edit {...props} mutationMode="pessimistic" >
    <SimpleForm>
      <TextInput source="name" label="User Name" fullWidth disabled />
      <TextInput source="user_email" label="User Email" fullWidth disabled />
      <TextInput source="user_phone" label="User Phone" fullWidth disabled />
      <TextInput source="candidate_profile_name" label="Candidate Name" fullWidth disabled />
      <TextInput source="candidate_profile_number" label="Candidate Number" fullWidth disabled />
    </SimpleForm>
  </Edit>
);

export default SavedCandidatesEdit;
