import React from 'react';
import {
  AutocompleteArrayInput,
  Create,
  FileInput,
  ImageField,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  required,
} from 'react-admin';

const CandidatesCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <FileInput source="avatar" label="Avatar" accept="image/*" placeholder={<p>Drop your image here</p>}>
          <ImageField source="src" title="title" />
        </FileInput>

        <TextInput source="profile_name" label="Name" validate={required()} fullWidth />
        <TextInput source="profile_title_dk" label="Danish title" validate={required()} fullWidth />
        <TextInput source="profile_title_en" label="English title" validate={required()} fullWidth />
        <TextInput source="profile_description_dk" label="Danish description" validate={required()} fullWidth />
        <TextInput source="profile_description_en" label="English description" validate={required()} fullWidth />
        <BooleanInput source="active" defaultValue={true} />

        <ArrayInput source="references">
          <SimpleFormIterator>
            <TextInput source="header_dk" label="Reference Header DK" validate={required()} fullWidth />
            <TextInput source="header_en" label="Reference Header EN" validate={required()} fullWidth />
            <TextInput source="description_dk" label="Reference Description DK" validate={required()} fullWidth />
            <TextInput source="description_en" label="Reference Description EN" validate={required()} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="educations">
          <SimpleFormIterator>
            <TextInput source="header_dk" label="Education Header DK" validate={required()} fullWidth />
            <TextInput source="header_en" label="Education Header EN" validate={required()} fullWidth />
            <TextInput source="description_dk" label="Education Description DK" validate={required()} fullWidth />
            <TextInput source="description_en" label="Education Description EN" validate={required()} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="certifications">
          <SimpleFormIterator>
            <TextInput source="header_dk" label="Certification Header DK" validate={required()} fullWidth />
            <TextInput source="header_en" label="Certification Header EN" validate={required()} fullWidth />
            <TextInput source="description_dk" label="Certification Description DK" validate={required()} fullWidth />
            <TextInput source="description_en" label="Certification Description EN" validate={required()} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ReferenceArrayInput reference="tools" source="tools" perPage={10000} >
          <AutocompleteArrayInput
            optionText={(record) => `${record.name} ${record.level}*`}
            filterToQuery={searchText => ({
                searchReference: searchText,
              })
            }
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput reference="skills" source="skills" perPage={10000} >
          <AutocompleteArrayInput
            optionText={(record) => `${record.name_en} ${record.level}*`}
            filterToQuery={searchText => ({
                searchReference: searchText,
              })
            }
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput reference="languages" source="languages" perPage={10000} >
          <AutocompleteArrayInput
            optionText="name_en"
            filterToQuery={searchText => ({
                searchReference: searchText,
              })
            }
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput reference="subcategories" source="subcategories" perPage={10000} >
          <AutocompleteArrayInput
            optionText="title_en"
            filterToQuery={searchText => ({
                searchReference: searchText,
              })
            }
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create >
  );
}

export default CandidatesCreate;
