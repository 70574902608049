import React from 'react';
import { useRecordContext } from 'react-admin';

const CombinedTextField = props => {
  const {
    source,
    source2=null,
    separator='',
    suffix='',
    tooltip='',
  } = props;

  const record = useRecordContext();

  return (
    <>
      {record ? (
        <span title={tooltip}>
          {`${record[source]}${separator}${record[source2]}${suffix}`}
        </span>
      ) : null}
    </>
  );
};

export default CombinedTextField;
